<template>
  <b-container class="p-0" fluid="lg">
    <div class="d-flex">
      <b-button class="ml-auto" size="sm" variant="secondary" @click="$router.go(-1)">
        <b-icon-chevron-left />Back
      </b-button>
    </div>
    <br />
    <b-card no-body :key="investor">
      <b-card-header class="d-flex py-1">
        <small class="text-muted">Detail of Ownership</small>
        <div class="ml-auto">
          <span class="text-danger">{{ error }}</span>
        </div>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" sm="6">
            <h2>
              <strong>{{ this.investor.ownership.amount }}</strong>
              <small class="ml-1 text-muted"><small>shares</small></small>
            </h2>
            <h5>
              <span class="text-capitalize">{{ this.investor.ownership.securities }}</span>
            </h5>
            <hr />
            <p v-for="(v, k) in this.investor.ownership.memo" :key="v">
              <small class="text-muted">{{ k }}</small>
              <br />
              {{ v }}
            </p>
          </b-col>
          <b-col cols="12" sm="6">
            <h2 class="text-capitalize">
              <strong>{{ this.investor.firstName }} {{ this.investor.lastName }}</strong>
            </h2>
            <h5 v-for="e in this.investor.emails" :key="e">{{ e }}</h5>
            <hr />
            <p v-for="(v, k) in this.investor.contact" :key="v">
              <small class="text-muted">{{ k }}</small>
              <br />
              {{ v }}
            </p>
          </b-col>
        </b-row>
        <div class="d-flex">
          <b-button size="sm" variant="primary" @click="$bvModal.show('newInvestorModal')">
            <b-icon-pencil />Edit Ownership
          </b-button>
          <b-button
            size="sm"
            variant="outline-secondary"
            class="ml-auto"
            @click="$bvModal.show('confirmDeleteModal')"
          >
            <b-icon-exclamation-triangle-fill />Delete
          </b-button>
        </div>
      </b-card-body>
    </b-card>
    <br />
    <br>

    <div v-if="this.securities.memo.type && this.securities.memo.type.toLowerCase() == 'equity'">
      <EquityMemo :securities="securities" :amount="this.investor.ownership.amount" />
    </div>
    
    <div v-if="this.securities.memo.type && this.securities.memo.type.toLowerCase() == 'debt'">
      <DebtMemo :securities="securities" :amount="this.investor.ownership.amount"/>
    </div>
    <br />
    <b-card no-body>
      <b-card-header class="d-flex">
        <small class="text-muted">Related Transfers</small>
        <div class="ml-auto">
          <b-icon v-if="isLoading" icon="arrow-clockwise" animation="spin"></b-icon>
          <span class="text-danger">{{ transferError }}</span>
        </div>
      </b-card-header>
      <b-list-group flush>
        <b-list-group-item v-for="t in this.investor.transfers" :key="t">
          <b-row>
            <b-col cols="12" sm="3">
              <h2>
                <strong>{{ formatter.format(t.amount) }}</strong>
              </h2>
            </b-col>
            <b-col cols="12" sm="3">
              <small v-if="t.date">
                <b-icon-calendar-date />
                {{ t.date.slice(0, 10) }}
              </small>
              <br />
              <strong class="text-uppercase">{{ t.status }}</strong>
            </b-col>
            <b-col cols="12" sm="3">
              <p>
                <small class="text-muted">
                  <b-icon-tag />transfer type
                </small>
                <br />
                <strong class="text-uppercase">{{ t.transferType }}</strong>
              </p>
              <p>
                <small class="text-muted">
                  <b-icon-tag />initial Acc
                </small>
                <br />
                <strong class="text-uppercase">{{ t.fromBankAccount }}</strong>
              </p>
              <p>
                <small class="text-muted">
                  <b-icon-tag />receiving Acc
                </small>
                <br />
                <strong class="text-uppercase">{{ t.toBankAccount }}</strong>
              </p>
            </b-col>

            <b-col cols="12" sm="3">
              <p v-for="(v, k) in t.memo" :key="v">
                <small class="text-muted">{{ k }}</small>
                <br />
                {{ v }}
              </p>
            </b-col>
            <b-col cols="12" sm="12" class="d-flex">
              <b-button
                size="sm"
                variant="outline-secondary"
                class="ml-auto"
                @click="deleteTransfer(t._id)"
              >
                <b-icon-exclamation-triangle-fill />Delete
              </b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item class="d-flex">
          <div class="mr-auto">
            <b-button
              @click="$bvModal.show('newTransfer')"
              variant="primary"
              size="sm"
              class="ml-auto"
            >
              <b-icon-plus />New Bank Transfer
            </b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card>

    <b-modal
      id="newTransfer"
      centered
      button-size="sm"
      @ok="addTransfer()"
      @close="resetTransferForm()"
      @cancel="resetTransferForm()"
    >
      <template #modal-title>Add New Transfer</template>
      <b-form>
        <b-form-group label="Amount in U.S. Dollar)">
          <b-input type="number" v-model="transferFormData.amount" required></b-input>
        </b-form-group>
        <b-form-group label="Date">
          <b-input type="date" v-model="transferFormData.date" required></b-input>
        </b-form-group>
        <b-form-group label="Status">
          <b-form-select
            v-model="transferFormData.status"
            :options="['pending', 'success', 'canceled']"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Inital Bank">
          <b-input v-model="transferFormData.fromBankAccount"></b-input>
        </b-form-group>
        <b-form-group label="Receiving Bank">
          <b-input v-model="transferFormData.toBankAccount"></b-input>
        </b-form-group>
        <b-form-group label="Usage">
          <b-form-select
            v-model="transferFormData.memo.usage"
            :options="['commitment', 'distribution', 'refund']"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Order">
          <b-input v-model="transferFormData.memo.order"></b-input>
          <b-form-text>Input the Order Number given to investor if any.</b-form-text>
        </b-form-group>
        <b-form-group label="Information">
          <b-input v-model="transferFormData.memo.info"></b-input>
          <b-form-text>Additional Information of this bank transfer</b-form-text>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal id="confirmDeleteModal" centered button-size="sm" @ok="deleteInvestor()">
      <template #modal-title>
        <span class="text-danger">
          <b-icon-exclamation-triangle-fill />Delete
        </span>
      </template>
      Delete this owner and its records?
    </b-modal>

    <b-modal
      id="newInvestorModal"
      centered
      button-size="sm"
      @ok="$bvModal.show('newOwnershipModal')"
      @cancel="resetInvestorForm()"
      @close="resetInvestorForm()"
    >
      <template #modal-title>Add New Ownership</template>
      <b-form>
        <b-form-group label="First Name">
          <b-input v-model="investorFormData.firstName" required></b-input>
        </b-form-group>
        <b-form-group label="Last Name">
          <b-input v-model="investorFormData.lastName" required></b-input>
        </b-form-group>
        <b-form-group label="Email">
          <b-input type="email" v-model="investorFormData.emails[0]"></b-input>
        </b-form-group>
        <b-form-group label="Phone">
          <b-input type="tel" v-model="investorFormData.contact.phone"></b-input>
        </b-form-group>
        <b-form-group label="Address">
          <b-input v-model="investorFormData.contact.address"></b-input>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="newOwnershipModal"
      centered
      button-size="sm"
      @ok="updateInvestor()"
      @cancel="resetInvestorForm()"
      @close="resetInvestorForm()"
    >
      <template #modal-title>Add Ownership</template>
      <b-form>
        <b-form-group label="Securities Name">
          <b-input v-model="investorFormData.ownership.securities" required></b-input>
        </b-form-group>
        <b-form-group label="Amount">
          <b-input type="number" v-model="investorFormData.ownership.amount" required></b-input>
        </b-form-group>
        <b-form-group label="Memo - Description">
          <b-input v-model="investorFormData.ownership.memo.description"></b-input>
        </b-form-group>
        <b-form-group label="Memo - Order ID if any">
          <b-input v-model="investorFormData.ownership.memo.order"></b-input>
        </b-form-group>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
const axios = require("axios");

import { mapGetters } from "vuex";
import EquityMemo from "../../components/equityMemo";
import DebtMemo from "../../components/debtMemo";
export default {
  components: { EquityMemo, DebtMemo },
  data: function() {
    return {
      error: "",
      transferError: "",
      investorFormData: {},
      transferFormData: {},
      id: {},
      captable: {},
      investor: {}
    };
  },
  computed: {
    ...mapGetters("user", {
      isLoggedIn: "getUserStatus",
      user: "getUser"
    }),
    index: function() {
      return this.captable.investors.findIndex(e => e._id == this.investor_id);
    },
    securities: function() {
      return this.captable.securities.find(
        e => e.securities == this.investor.ownership.securities
      );
    },
    formatter:function(){
      return new Intl.NumberFormat('en-US',{
        style: 'currency',
        currency: 'USD'
      })
    }
  },
  methods: {
    resetTransferForm: function() {
      console.log("reset transfer");
      this.transferFormData.amount = null;
      this.transferFormData.date = "/01/01/2021";
      this.transferFormData.status = "pending";
      this.transferFormData.transferType = "WIRE";
      this.transferFormData.fromBankAccount = null;
      this.transferFormData.toBankAccount = null;
      this.transferFormData.memo = {
        order: "",
        usage: "commitment",
        info: ""
      };
    },
    resetInvestorForm: function() {
      console.log("deep cp");
      this.investorFormData = JSON.parse(JSON.stringify(this.investor));
      this.investorFormData.contact = this.investorFormData.contact
        ? this.investorFormData.contact
        : {};
      this.investorFormData.ownership.memo = this.investorFormData.ownership
        .memo
        ? this.investorFormData.ownership.memo
        : {};
    },

    async deleteTransfer(transferId) {
      this.$store.isLoading = true;
      let transferIndex = this.captable.investors[
        this.index
      ].transfers.findIndex(t => t._id == transferId);
      var originData = {};
      Object.assign(originData, this.captable.investors[this.index].transfers);
      this.captable.investors[this.index].transfers.splice(transferIndex, 1);

      axios
        .put("/.netlify/functions/captable", this.captable)
        .then(res => {
          this.transferError = "";
          this.captable = res.data;
        })
        .catch(err => {
          Object.assign(
            this.captable.investors[this.index].transfers,
            originData
          );
          this.transferError = err;
        })
        .finally(() => {
          this.investor = this.captable.investors[this.index];
          this.$store.isLoading = false;
        });
    },

    async addTransfer() {
      this.$store.isLoading = true;
      var originData = {};
      Object.assign(originData, this.captable.investors[this.index].transfers);

      if (Array.isArray(this.captable.investors[this.index].transfers)) {
        this.captable.investors[this.index].transfers.push(
          this.transferFormData
        );
      } else {
        this.captable.investors[this.index].transfers = [this.transferFormData];
      }

      axios
        .put("/.netlify/functions/captable", this.captable)
        .then(res => {
          this.transferError = "";
          this.captable = res.data;
        })
        .catch(err => {
          this.transferError = err;
          Object.assign(
            this.captable.investors[this.index].transfers,
            originData
          );
        })
        .finally(() => {
          this.investor = this.captable.investors[this.index];
          this.resetTransferForm();
          this.$store.isLoading = false;
        });
    },

    async deleteInvestor() {
      this.$store.isLoading = true;
      this.captable.investors.splice(this.index, 1);

      axios
        .put("/.netlify/functions/captable", this.captable)
        .then(res => {
          this.captable = res.data;
          this.$router.go(-1);
        })
        .catch(err => {
          this.error = err;
        })
        .finally(() => {
          this.$store.isLoading = false;
        });
    },
    async updateInvestor() {
      this.$store.isLoading = true;

      var originData = {};
      Object.assign(originData, this.investor);

      this.captable.investors[this.index] = JSON.parse(
        JSON.stringify(this.investorFormData)
      );

      axios
        .put("/.netlify/functions/captable", this.captable)
        .then(res => {
          this.captable = res.data;
          this.investor = this.captable.investors[this.index];

          this.error = "";
        })
        .catch(err => {
          Object.assign(this.captable.investors[this.index], originData);
          Object.assign(this.investor, originData);

          this.error = err;
        })
        .finally(() => {
          this.resetInvestorForm();
          this.$store.isLoading = false;
        });
    }
  },
  created: function() {
    this.id = this.$route.params._id;
    this.investor_id = this.$route.params.investor_id;
    this.investor = this.$route.params.investor;
    this.captable = this.$route.params.captable;
    this.resetInvestorForm();
    this.resetTransferForm();
  }
};
</script>
